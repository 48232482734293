<template>
  <div>
    <v-card elevation="0">
      <validation-observer ref="observer">
        <v-form class="mt-4">
          <v-container class="pt-5">
            <v-row>
              <v-col md="2" class="d-none d-md-block" />
              <v-col md="4" cols="12">
                <validation-provider v-slot="{ errors }" vid="login">
                  <v-text-field
                    v-model="usuarioNovo.login"
                    outlined
                    dense
                    hide-details="auto"
                    :readonly="readonly"
                    :disabled="$store.state.layout.loading"
                    label="Login"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col md="4" cols="12">
                <validation-provider v-slot="{ errors }" vid="nome">
                  <v-text-field
                    v-model="usuarioNovo.nome"
                    outlined
                    dense
                    :readonly="readonly"
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Nome"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col md="2" class="d-none d-md-block" />
            </v-row>
            <v-row>
              <v-col md="2" class="d-none d-md-block" />
              <v-col md="4" cols="12">
                <validation-provider v-slot="{ errors }" vid="senha">
                  <v-text-field
                    v-model="usuarioNovo.senha"
                    outlined
                    dense
                    :readonly="readonly"
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Senha"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col md="4" cols="12">
                <validation-provider v-slot="{ errors }" vid="senha">
                  <v-text-field
                    v-model="usuarioNovo.senha_confirmation"
                    outlined
                    :readonly="readonly"
                    dense
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Senha Confirmação"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col md="2" class="d-none d-md-block" />
            </v-row>
            <v-row>
              <v-col md="2" class="d-none d-md-block" />
              <v-col md="4" cols="12">
                <validation-provider v-slot="{ errors }" vid="email">
                  <v-text-field
                    v-model="usuarioNovo.email"
                    outlined
                    dense
                    :readonly="readonly"
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    :error-messages="errors"
                    label="Email"
                  />
                </validation-provider>
              </v-col>
              <v-col md="4" cols="12">
                <validation-provider v-slot="{ errors }" vid="grupos">
                  <v-select
                    :items="listaGrupos"
                    :readonly="readonly"
                    label="Selecione as permissões"
                    v-model="usuarioNovo.grupos"
                    :error-messages="errors"
                    multiple
                    item-value="id"
                    item-text="nome"
                  >
                  </v-select>
                </validation-provider>
              </v-col>
              <v-col md="2" class="d-none d-md-block" />
            </v-row>
            <v-row>
              <v-col md="5" />
              <v-col md="2" class="d-flex justify-center">
                <v-switch
                  v-model="usuarioNovo.ativo"
                  class="mt-0 pt-0"
                  :disabled="$store.state.layout.loading"
                  :readonly="readonly"
                  :label="descricaoAtivo"
                />
              </v-col>
              <v-col md="5" />
            </v-row>
          </v-container>
        </v-form>
      </validation-observer>
      <v-divider class="mt-4" />
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="text-left">
            <btn-salvar
              :temPermissao="
                $store.getters['usuario/temPermissao']('mostrar.usuarios')
              "
              :carregando="carregandoSalvar"
              :disabled="salvarDisabled"
              @click="$emit('salvar', usuarioNovo)"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import grupos from "@/api/grupos";

export default {
  props: {
    validacaoFormulario: {
      type: Object,
      default: () => {
        return {};
      },
    },
    carregandoSalvar: {
      type: Boolean,
      default: false,
    },
    salvarDisabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    usuario: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      listaGrupos: [],
      selectedItem: [],
      usuarioNovo: {
        login: null,
        nome: null,
        email: null,
        ativo: true,
        senha: null,
        empresa_id: this.$store.state.usuario.usuario.empresa_id,
        senha_confirmation: null,
        grupos: [],
      },
    };
  },
  computed: {
    descricaoAtivo() {
      return this.usuarioNovo.ativo ? "Ativo" : "Inativo";
    },
  },
  mounted() {
    this.gruposListar();
  },

  watch: {
    validacaoFormulario(val) {
      if (!val) return;

      return this.$refs.observer.setErrors(val);
    },

    usuario(usuario) {
      this.usuarioNovo = usuario;
    },
  },

  methods: {
    async gruposListar() {
      try {
        const resposta = await grupos.listar();
        this.listaGrupos = resposta.data.data;
      } catch (e) {
        this.$router.push("/usuarios", () =>
          this.$snackbar.mostrar({
            cor: "error",
            mensagem: this.$erroApi._(e),
          })
        );
      }
    },
  },
};
</script>
<style scoped>
.grupos {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;
  padding: 1em;
}
.grupos-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-text-field {
  padding: 0;
}
</style>
