import axios from '@/plugins/axios'

export default {

  async listar () {
    return await axios.get(`grupos`)
  },

  async buscar (id) {
    return await axios.get(`grupos/${id}`)
  },

  async inserir (dados) {
    return await axios.post('grupos', dados)
  },

  async atualizar (id, dados) {
    return await axios.put(`grupos/${id}`, dados)
  },

  async listarPermissoesGrupo (id) {
    return await axios.get(`grupos/${id}/permissoes`)

  },

  async vincularPermissoesGrupo (id, dados) {
    return await axios.put(`grupos/${id}/permissoes`, dados)
  },
  
  async buscarPermissoes (id) {
    return await axios.get(`grupos/${id}/permissoes`)
  },

  async atualizarPermissoes (id, dados) {
    return await axios.put(`grupos/${id}/permissoes`, dados)
  },
}
